* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Roboto, Saira Condensed";
  background-color: #a0a0a0;
}
